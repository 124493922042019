import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";
import { IonInput } from "@ionic/angular";
import { ModUtils } from "src/app/static";

@Component({
  selector: "app-common-money-input",
  templateUrl: "./common-money-input.component.html",
  styleUrls: ["./common-money-input.component.scss"],
})
export class CommonMoneyInputComponent implements OnInit {
  @ViewChild("notFormatted", { read: ElementRef, static: false })
  notFormattedInput;
  @Output() moneyValueChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() moneyValue: number = 0;
  @Input() errorMsg = "";
  @Input() maxAmount;
  @Input() minAmount;

  @Input() isApplyOffer;
  @Input() isDisabled: boolean = false;
  isModifying = false;
  formattedInput: string = "";

  constructor() {}

  ngOnInit() {
    this.formatSeparatorWithCommas();
  }

  activateModification() {
    this.isModifying = true;
    setTimeout(() => {
      const targetInput = this.notFormattedInput.nativeElement as IonInput;
      targetInput.setFocus();
    }, 0);
  }

  emitChanges() {
    this.moneyValueChange.emit(this.moneyValue);
  }

  ngOnChanges() {
    this.formatSeparatorWithCommas();
  }

  updateFormattedData() {
    if (this.maxAmount && this.moneyValue > this.maxAmount) {
      this.moneyValue = this.maxAmount;
    }

    this.isModifying = false;
    this.formatSeparatorWithCommas();
    this.emitChanges();
  }

  formatSeparatorWithCommas() {
    if(!this.isApplyOffer){
      if (this.minAmount) {
        if (!this.moneyValue || this.moneyValue < this.minAmount) {
          this.moneyValue = this.minAmount;
        }
      } else {
        this.moneyValue = 0;
      }
    }
    if(typeof this.moneyValue == 'string'){
      this.moneyValue = Number(this.moneyValue);
    }
    this.formattedInput = ModUtils.formatSeparatorWithCommas(this.moneyValue.toFixed(0).toString());
  }
}
