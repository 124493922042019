import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { catchError, finalize, map, pairwise, shareReplay, tap } from 'rxjs/operators';
import { PayrollReceipt, PayslipYear } from '../modules/payslip/filter/filter.component';
import { Observable } from 'rxjs';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { PayrollReceipts } from '../models/PayrollReceipts';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Injectable({
  providedIn: 'root'
})
export class PayrollAdvanceService {
  id: any;
  number: number = 0.00;
  comission: number = 0.00;
  date: any;
  banks: any;

  isLoading: boolean = false;

  accountSelected: any = null;
  paymentDate: any;

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    })
  };

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private file: File,
    private fileOpener: FileOpener,
    public loadingCtrl: LoadingController,
    public platform: Platform,
    private iab:InAppBrowser,
    private socialSharing : SocialSharing, 
    private alertController:AlertController

  ) {

  }


  getPaysLip(request: {
    sort: 'ASC' | 'DESC',
    from?: string,
    to?: string
  }): Observable<Array<PayslipYear>> {
    return this.http.post<{ data: Array<PayslipYear> }>(`${environment.API_URL}/payslip`, request)
      .pipe(
        map(({data}) => data),
      );
  }

  getReceipt(id: number): Observable<PayrollReceipt> {
    return this.http.get<{ data: PayrollReceipt }>(`${environment.API_URL}/payslip/${id}`).pipe(map(result => result.data));
  }
  downloadReceipt=async (base64)=> {
    const writeDirectory = this.platform.is('ios') ? this.file.dataDirectory : this.file.externalDataDirectory;
    this.file.writeFile(writeDirectory, 'recibo.xml', base64, {replace: true})
      .then((value) => {
        if(this.platform.is('ios')){
        this.fileOpener.open(`${writeDirectory}recibo.xml`, 'text/xml')
          .then(() => console.log('File is opened'))      
          .catch(e => console.log('Error opening file', e));
        }else{
          this.socialSharing.share(null, null,`${writeDirectory}recibo.xml`, null).then((data) => {
            console.log('Shared via SharePicker'); 
           }).catch((err) =>  console.log('Was not shared via SharePicker'));
        }
      })
      .catch(() => {  
        console.error('Error writing xml file');
      });
    
       }
  async presentAlertChooseType(data:any) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirmar!',
      message: 'Que tipo de archivo desea ver?',
      buttons: [
        {
          text: 'PDF',
          handler: () => {
            this.itemSelected(data);
          },
        },
        {
          text: 'XML',
          handler: async () => {
            let options;
            if(this.platform.is("ios")){
              const browser = this.iab.create(environment.XML_BASE_URL+data.xml,'_blank', options);
              browser.on('loadstart').subscribe((e)=>{
                  document.addEventListener('deviceready',  ()=> {
                    setTimeout(()=> {
                      this.downloadReceipt(data.xml_string); 
                    }, 1000);
                  });
                browser.close()
            
                  })
            }else{
              options = {
                hideurlbar: 'yes',
                location: 'yes',
                toolbarcolor: '#2196F3',
                toolbarposition: 'top',
                closebuttoncolor: '#ffffff',
                closebuttoncaption: 'Cerrar',
                presentationstyle: 'pagesheet',
                hidenavigationbuttons: 'yes',
              };
              const browser = this.iab.create(environment.XML_BASE_URL+data.xml,'_blank', options);
              browser.on('loadstop').subscribe(()=>{
                browser.executeScript({ code: "\
                document.body.style.backgroundColor = 'white';"
              });
              })
              browser.on('loadstart').subscribe((e)=>{
                  document.addEventListener('deviceready',  ()=> {
                    setTimeout(()=> {
                      this.downloadReceipt(data.xml_string);
                    }, 1000);
                  });
                  })
            }
       
         }
        },
      ],
    });

    await alert.present();
  }
 itemSelected(item: PayrollReceipt | any) {
    
    console.log('===>ITEM:', item);
    this.readPayslip(item.id);

    // const writeDirectory = this.file.externalApplicationStorageDirectory;
    const writeDirectory = this.platform.is('ios') ? this.file.dataDirectory : this.file.externalDataDirectory;

    // this.file.checkDir(this.file.dataDirectory, '')
    //   .then(_ => console.log('Directory exists'))
    //   .catch(err => console.log('Directory doesn\'t exist'));


    console.log('====> writeDirectory', writeDirectory);

    this.file.writeFile(writeDirectory, 'recibo.pdf', this.convertBase64ToBlob(item.pdf_string_sign, 'data:application/pdf;base64'), {replace: true})
      // console.log('===> RESULT, ', result);
      .then((value) => {
        console.log('==> VALUIE', value);
        this.fileOpener.open(`${writeDirectory}recibo.pdf`, 'application/pdf')
          .then(() => console.log('File is opened'))
          .catch(e => console.log('Error opening file', e));


        // this.opener.open(writeDirectory + filename, 'application/pdf')
        //   .catch(() => {
        //     console.log('Error opening pdf file');
        //   });
      })
      .catch(() => {
        console.error('Error writing pdf file');
      });

    // this.fileOpener.showOpenWithDialog(item.pdf_string, 'application/pdf')
    //   .then(() => console.log('File is opened'))
    //   .catch(e => console.log('Error opening file', e));
  }
  createPdf(base64:any,name:string) {
    console.log('===>ITEM:',name);
    const writeDirectory = this.platform.is('ios') ? this.file.dataDirectory : this.file.externalDataDirectory;
    this.file.writeFile(writeDirectory, name, this.convertBase64ToBlob(base64, 'data:application/pdf;base64'), {replace: true})
      .then((value) => {
        console.log('==> VALUIE', value);
        this.fileOpener.open(`${writeDirectory}${name}`, 'application/pdf')
          .then(() => console.log('File is opened'))
          .catch(e => console.log('Error opening file', JSON.stringify(e)));
      })
      .catch(() => {
        console.error('Error writing pdf file');
      });
  }
  convertBase64ToBlob(b64Data, contentType): Blob {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }


  readPayslip(receiptId: number): void {
    this.http.get(`${environment.API_URL}/payslip/edit/${receiptId}`)
      .pipe(
        catchError((err) => {
          console.log('Ocurrio un error');
          return err;
        })).subscribe(_ => {
      console.log('Operación exitosa');
    });
  }

  //Obtener payroll_advanceds data
  getData() {
    return this.http.get(environment.API_URL + '/payroll_advanceds/get_data');
  }

  //mandar payroll_advanceds data
  postData(data: any) {
    // Presentamos el Loading al inicio de la llamada
    this.presentLoading();
    return this.http.post(environment.API_URL + '/payroll_advanceds/create', data, this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        shareReplay(1),
        finalize(() => this.dismissLoading())
      );
  }

  postNewAccountData(data: any) {
    return this.http.post(environment.API_URL + '/payroll_advanceds/create_bank', data, this.httpOptions);
  }

  dropAccount(account_id) {
    return this.http.get(environment.API_URL + '/payroll_advanceds/drop_bank/' + account_id);
  }

  // Creación del loading
  async presentLoading() {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: 'Estamos procesando tu transacción por favor espera un momento.'
    }).then((a) => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss();
        }
      });
    });
  }

  // Cierre del loading
  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss();
  }
  SingedPayslip(receipt: PayrollReceipts | any) {
    if (receipt.checkbox == 'activo'){
      this.id = receipt.high_employee_id;
      console.log(this.id);
    }else{
       this.id = receipt.id;
       console.log(this.id);

    }
    return this.http.put(`${environment.API_URL}/payslip/signed/${this.id}`,receipt);
}
 

  }
  
