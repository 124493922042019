import { PayrollAdvanceService } from "src/app/services/payroll-advance.service";
import { LoadingController, ModalController, Platform } from "@ionic/angular";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { SignaturePad } from "angular2-signaturepad";
import { PayrollReceipts } from "src/app/models/PayrollReceipts";
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AlertService } from '../../../services/alert.service';
@Component({
  selector: "app-payslip-firm",
  templateUrl: "./payslip-firm.component.html",
  styleUrls: ["./payslip-firm.component.scss"],
})
export class PayslipFirmComponent implements OnInit {
  @ViewChild("signaturePad", { static: false }) signaturePad: SignaturePad;

  private signaturePadOptions: Object = {
    maxWidth: 1,
    minWidth: 1,
    canvasWidth: window.innerWidth + 180,
    canvasHeight: 120
  };
  @Input() data: any;
  payroll: PayrollReceipts = new PayrollReceipts();
  public signatureImage: string;
  checkbox: any = "inactivo";
  isLoading: boolean = false;
  web:any=false
  constructor(
    private modalcontroller: ModalController,
    public payrollAdvanceService: PayrollAdvanceService,
    public loadingController: LoadingController,
    private screenOrientation: ScreenOrientation,
    private platform:Platform,
    private alertService:AlertService) {
      
     
      if (this.platform.is('cordova')) 
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE)
      else
        this.web=true
    }

  ngOnInit() {
    //    console.log("prueba",this.data);
  }

  drawComplete() {
    this.signatureImage = this.signaturePad.toDataURL();
    this.payroll.signed_image = this.signatureImage;
  }
  clear() {
    this.signatureImage = "";
    this.signaturePad.clear();
  }
  closeModal() {
    this.modalcontroller.dismiss();
  }
  ionViewWillLeave(){
    if (this.platform.is('cordova')) 
    this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT)
  }
  payslipFirm(checked) {
    this.alertService.presentLoading('Espere un momento');
    this.payroll = this.data;
    this.payroll.checkbox = checked;
    this.payroll.signed_image = this.signatureImage.split(',')[1];
  
    let name=this.data.high_employee.name+' '+this.data.high_employee.paternal_last_name+" "+this.data.high_employee.mother_last_name
      this.payrollAdvanceService
        .SingedPayslip({...this.payroll,name,email:this.data.high_employee.email})
        .subscribe((resp) => {
          this.alertService.dismissLoading();
         this.modalcontroller.dismiss(this.payroll);
        },(err)=>console.log('err',err));

  }

  changeBackground(checked) {
    if (checked) {
      this.checkbox = "activo";
      this.payroll.checkbox = this.checkbox;
    } else {
      this.checkbox = "inactivo";
      this.payroll.checkbox = this.checkbox;
    }
  }
  async presentLoading() {
    this.isLoading = true;
     return await this.loadingController.create({
      message: 'Espere un momento',
    }).then(a => {
    a.present().then(() => {
      if (!this.isLoading) {
        a.dismiss();
      }
    })
   });
  }
  async DismissLoading() {
  this.isLoading = false;
  
  return await this.loadingController.dismiss();
  
  }

}
