export const environment = {
    production: true,
    API_URL: 'https://admin.paco.app/api',
    ONE_SIGNAL_APP_ID: '112de181-20c6-4857-b6ce-d8e7d2531790',
    FIREBASE_SENDER_ID: 'FIREBASE_SENDER_ID',
    MAPSKEY: 'pk.eyJ1IjoiamN2aXpjYWlubyIsImEiOiJja2E4Zml1NHowZTkzMnJwaWdiMHlsMjZtIn0.6ciM4BUEDiwrrJi6xqlnPg',
    SMARTLOOK_KEY: '4a0168938f55324aa5d5d67e7ad826f6066894f7',
    HELP_URL:'https://ayuda.paco.app/portal/es/kb/soporte-t%C3%A9cnico/aplicaci%C3%B3n-m%C3%B3vil',
    API_KEY_ZOHO_ANDROID:'NDX2fFtcVzvmXagFuiYFU9bZO5%2BMffDwHepHrP6rzCaZxCuD09D%2FbtBdMne%2FoOWe',
    API_KEY_ZOHO_IOS:"NDX2fFtcVzvmXagFuiYFU9bZO5%2BMffDwHepHrP6rzCaZxCuD09D%2FbtBdMne%2FoOWe",
    ACCESS_TOKEN_ZOHO_ANDROID:'harFESiTd10OTQiLbqinCRLB9V2HC%2B4%2BI8mJH%2FBVMEufPwz58zqm4%2F711J12BPNi8Z5Q76rP5jwyAc8Op4fScvenkLXuwEY%2FIWwN2mJAEE73w%2BSfc0JrKw%3D%3D',
    ACCESS_TOKEN_ZOHO_IOS:'Mv4owJwcquf5YSOcr6Jw9dSLAlN7xRUZsKJI1OWlX%2B4jPOaKnMafVeiJ3oXcNhBDW7BD3CoDicqXFdSH1eDZTHNVzYk0zvpIEuLyF8%2FokpOnAGBPGrWuow%3D%3D',
    XML_BASE_URL:'https://admin.paco.app',
   BUGSNAG_API_KEY:'8bbb698c6e080ffebf89835df2d45f31',
    APP_ID_IOS:'6471745414',
    APP_GENERAL_NAME:'Grupo GIM'
};
