export class PayrollReceipts {
    date: any;
    final_date_payment: any;
    first_view_date: any
    fiscal_folio: any;
    high_employee_id: any;
    id: any;
    initial_payment_date: any;
    last_view_date: any;
    pdf_string: any;
    signature_date: any;
    signed: any;
    signed_image: any;
    created_at: Date;
    updated_at: Date;
    checkbox:any;
}