import { Injectable } from "@angular/core";
import { HttpClient,HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { tap, catchError } from "rxjs/operators";
import { ToastController, LoadingController, Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';
import { Router } from '@angular/router';
import { File } from '@ionic-native/file/ngx';
import { throwError } from 'rxjs';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: "root",
})
export class EmployeeVoiceService {

  items:any;

  isLoading: boolean = false;

  formData  = new FormData();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    }),
  };

  constructor(
    private http: HttpClient, 
    private authService:AuthService, 
    private toastController:ToastController, 
    public loadingCtrl: LoadingController,
    private nativeHTTP: HTTP,
    private router:Router,
    private platform: Platform,
  ) {}

  sendMessage(form) {
    return this.http.post(environment.API_URL + "/voice_employees/create", form, this.httpOptions);
  }

  //Obtener data
  getComments(order_by,page = null, search = null) {
    let parameters = '';
    parameters = '?order_by='+order_by;
    if(!this.isEmpty(page)){
      parameters = parameters + '&page='+page;
    }
    if(!this.isEmpty(search)){
      parameters = parameters + '&search='+search;
    }
    let endpoint = '/voice_employees' + parameters;
    return this.http.get(environment.API_URL + endpoint, this.httpOptions)
    .pipe(
      tap(comments => {
        return comments;
      }),
      catchError(this.handleError)
    )
  }

  isEmpty(str) {
    return (!str || str.length === 0 );
  }

  getSubjects() {
    return this.http
      .get(environment.API_URL + "/voice_employees/get_subjects", this.httpOptions)
      .pipe(
        tap((response) => {
          return response;
        })
      );
  }

  getSentList(order_by) {
    let data = { 'order_by' : order_by };
    return this.http
      .post(environment.API_URL + "/voice_employees/get_sent_list",JSON.stringify(data), this.httpOptions)
      .pipe(
        tap((response) => {
          return response;
        })
      );
  }

  getComment(id) {
    return this.http
      .get(environment.API_URL + "/voice_employees/get_sent/ " + id, this.httpOptions)
      .pipe(
        tap((response) => {
          return response;
        })
      );
  }

  async sendVoiceEmployee(formData) {

    this.nativeHTTP.setDataSerializer('multipart');

    let file = formData.get('document');

    console.log('File: '+JSON.stringify(file));

    console.log('entro a enviar comentarios');

    const hasPreviousSession = await this.authService.updateAuthStateIfTokenProvided();

    let token_header = `${this.authService.token.token_type} ${this.authService.token.token}`;

    console.log('Token: '+token_header);

    //console.log('data: '+JSON.stringify(formData));

    //console.log('url: '+imageURL);

    let datos = {
      'comments': formData.get('comments'),
      'voice_employee_subject_id': formData.get('voice_employee_subject_id'),
      'document': formData.get('document64'),
      'images': formData.getAll('images[]'),
      'is_anonyme': formData.get('is_anonyme'),
      'voice_employee_id': formData.get('voice_employee_id'),
      'browser': 'web',
    };

    let headers_request = { Authorization: token_header };


    this.presentLoading();
    if(this.platform.is('desktop') || this.platform.is('mobileweb')){
      console.log('browser');
      try {
        this.http.post(environment.API_URL + "/voice_employees/create", datos) 
        .subscribe( data => {
            console.log(data);
          }, error => {
            console.log(error);
          }
        );
        this.dismissLoading();
        this.router.navigateByUrl('/employee-voice/employee-voice-sent-modal');	
      } catch (error) {
        console.log(error);
      }
    }else{
      await this.nativeHTTP.sendRequest(environment.API_URL + "/voice_employees/create",{
        method: 'post',
        data: formData,
        headers: headers_request,
      })
      .then(response => {
        // prints 200
        console.log("Response successfull status: "+response.status);
        console.log("Response successfull body: "+JSON.stringify(response));
        let data = JSON.parse(response.data);
        if(data['status'] == 200){
          this.dismissLoading();
          //this.presentToast(data['successMessage'],'success');
          this.router.navigateByUrl('/employee-voice/employee-voice-sent-modal');
        }else if(data['status'] == 500){
          this.dismissLoading();
          this.presentToast(data['errorMessage'],'danger');
        }
      })
      .catch(error => {
        // prints 403
        console.log("Response status: "+error.status);
        // prints Permission denied
        console.log("Response error: "+error.error);
        console.log('error en carga', JSON.stringify(error));
        this.dismissLoading();
        this.presentToast('File native upload error: '+JSON.stringify(error),'danger');
      });
    }
  }

  loadFilter(orderBy){
    this.items = [];
    this.getSentList(orderBy).subscribe(response => {
      console.log("pponse",response);
      if(response['status'] == 200){
        response['data'].forEach(element => {
          this.items.push(element);
        }); 
      }
    });
  }

  //Filtrar busqueda
  filterItems(searchTerm){
    //console.log(searchTerm);
    if(searchTerm == ''){
      //console.log('vacio');
      return this.items;
    }
    //console.log('filro');
    return this.items.filter((comment) => {
      return comment.subject.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
  }

  // Presenta el toast con el error
  async presentToast(msg,color) {
    const toast = await this.toastController.create({
        message: msg,
        duration: 5000,
        position: 'top',
        color: color,
        cssClass: 'toast',
    });
    toast.present();
  }

  // Creación del loading
  async presentLoading() {
    this.isLoading = true;
    return await this.loadingCtrl.create({})
    .then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => console.log());
        }
      });
    });
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.'
    );
  };

  // Cierre del loading
  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
  }
  
}
