import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { isNumber } from 'util';

@Component({
  selector: 'app-modal-call',
  templateUrl: './modal-call.component.html',
  styleUrls: ['./modal-call.component.scss'],
})
export class ModalCallComponent implements OnInit {

  @Input() title: any;
  @Input() number: any;
  @Input() membership: any;
  @Input() contract_number: any;
  
  constructor(private modalController:ModalController,private callNumber: CallNumber, private platform: Platform) { }

  ngOnInit() {

    console.log("title", this.title);
  }

  call(){
    if(this.platform.is('desktop') || this.platform.is('mobileweb')){
      window.open(`tel:${this.number}`, '_system')
    } else {
      this.callNumber.callNumber(this.number, true)
        .then(res => console.log('Launched dialer!', res))
        .catch(err => console.log('Error launching dialer', err));
    }
  }


  closeModal(){
    this.modalController.dismiss();
  }
}
