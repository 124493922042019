import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-terms-information-modal',
  templateUrl: './terms-information-modal.component.html',
  styleUrls: ['./terms-information-modal.component.scss'],
})
export class TermsInformationModalComponent implements OnInit {

  @Input() signature_url: string;

  user: any;

  constructor(
    public modalController : ModalController, 
    private authService:AuthService
  ) { }

  ngOnInit() {
    this.authService.user().subscribe(
      (data:any)=>{
        console.log("data", data);
        this.user = data;
      }
    );
  }


  closeModal(){
    this.modalController.dismiss();
  }
}
