import { SignaturePadModule } from 'angular2-signaturepad';
import { PayslipFirmComponent } from './modals/payslip-firm/payslip-firm.component';
import { TermsSegurosComponent } from './modals/terms-seguros/terms-seguros.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CommonMoneyInputComponent } from './common-money-input/common-money-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { NotificationsHomeModalComponent } from './modals/notifications-home-modal/notifications-home-modal.component';
import { TermsModalComponent } from './modals/terms-modal/terms-modal.component';
import { NoticePrivacyComponent } from './modals/notice-privacy/notice-privacy.component';
import { TermsInformationModalComponent } from './modals/terms-information-modal/terms-information-modal.component';
import { TermsFormatModalComponent } from './modals/terms-format-modal/terms-format-modal.component';
import { DomiciliationComponent } from "src/app/components/modals/domiciliation/domiciliation.component";
import { SignatureComponent } from "src/app/components/modals/signature/signature.component";
import { SurveyNotAvailableComponent } from '../components/modals/survey-not-available/survey-not-available.component';
import { ModalCallComponent } from '../modules/assist-secure/modal-call/modal-call.component';
import { VideoPlayerComponent } from './modals/video-player/video-player.component';
import { MenuDocsComponent } from './modals/menu-docs/menu-docs.component';
import { OtpComponent } from './otp/otp.component';
import { ConfirmNipComponent } from './modals/confirm-nip/confirm-nip.component';
import { CreateNipComponent } from './modals/create-nip/create-nip.component';
import { VerifyPinComponent } from './modals/verify-pin/verify-pin.component';
import { InputCustomComponent } from './modals/input-custom/input-custom.component';
import { FirmModalComponent } from './modals/firm-modal/firm-modal.component';


const components = [
  CommonMoneyInputComponent,
  DatepickerComponent,
  NotificationsHomeModalComponent,
  TermsModalComponent,
  NoticePrivacyComponent,
  TermsInformationModalComponent,
  TermsFormatModalComponent,
  TermsSegurosComponent,
  DomiciliationComponent,
  PayslipFirmComponent,
  SignatureComponent,
  SurveyNotAvailableComponent,
  ModalCallComponent,
  VideoPlayerComponent,
  MenuDocsComponent,
  OtpComponent,
  ConfirmNipComponent,
  CreateNipComponent,
  VerifyPinComponent,
  InputCustomComponent,
  FirmModalComponent
];

const modalComponents = [
  NotificationsHomeModalComponent,
  TermsModalComponent,
  NoticePrivacyComponent,
  TermsInformationModalComponent,
  TermsFormatModalComponent,
  SurveyNotAvailableComponent,
  TermsSegurosComponent,
  PayslipFirmComponent,
  SignatureComponent,
  DomiciliationComponent,
  ModalCallComponent,
  VideoPlayerComponent,
  MenuDocsComponent,
  ConfirmNipComponent,
  CreateNipComponent,
  VerifyPinComponent,
  InputCustomComponent,
  FirmModalComponent
]

@NgModule({
  entryComponents: modalComponents,
  declarations: components,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    IonicModule,
    SignaturePadModule,
  ],
  exports: components,
  providers: [
    InAppBrowser
  ]
})
export class CommonComponentsModule {
}
