import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { ModUtils } from "src/app/static";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-nip',
  templateUrl: './create-nip.component.html',
  styleUrls: ['./create-nip.component.scss'],
})
export class CreateNipComponent implements OnInit {

  options: InAppBrowserOptions;
  product: any = null;
  mobile = "";
  data:any
  constructor(private modalController:ModalController,public toastCtrl: ToastController, private router: Router, private platform: Platform, private iab: InAppBrowser, private cdRef: ChangeDetectorRef,
    private alertService:AlertService) {
    }

  ngOnInit() {}
  close(){
    this.modalController.dismiss()
  }

  handleNumber($event){
    console.log('number received',$event)
    let number=$event
   // this.sendPin(number)
    this.modalController.dismiss(number)
  
  }
  sendPin(pin){
      let navigationExtras: NavigationExtras = {
        state: {
          pin,
        //  product: this.product,
        //  mobile: this.mobile,
         data:this.data
        }
      };
      this.alertService.setErrMessage('')
      this.alertService.setExistError(false)
      this.router.navigate(['/confirm-pin'],navigationExtras);
  }

  openHelp(){
    if(this.platform.is("ios")){
      this.options = {
        location: 'no',
        toolbarcolor: '#2196F3',
        toolbarposition: 'top',
        closebuttoncolor: '#ffffff',
        closebuttoncaption: 'Cerrar',
        lefttoright: "yes",
        hidenavigationbuttons: 'yes'
      };
    }else{
      this.options = {
        hideurlbar: 'yes',
        location: 'yes',
        toolbarcolor: '#2196F3',
        toolbarposition: 'top',
        closebuttoncolor: '#ffffff',
        closebuttoncaption: 'Cerrar',
        presentationstyle: 'pagesheet',
        hidenavigationbuttons: 'yes'
      };
    }
    const browser = this.iab.create(environment.HELP_URL,'_blank', this.options);
  }

  async presentToastError(message: any) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 5000,
      position: "top",
      color: "danger",
    });
    toast.present();
  }
}
