import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { ModUtils } from "src/app/static";
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { LoadingController, ToastController, Platform, ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-confirm-nip',
  templateUrl: './confirm-nip.component.html',
  styleUrls: ['./confirm-nip.component.scss'],
})
export class ConfirmNipComponent implements OnInit {
 @Input() pin:string=''
  existError = false;
  errorMsg: string;
  isLoading: boolean = false;
  options: InAppBrowserOptions;
  product: any = null;
  mobile = "";
  data:any
  constructor(private modalController:ModalController,private router: Router, private platform: Platform, public loadingCtrl: LoadingController, public authService: AuthService, private route: ActivatedRoute, private iab: InAppBrowser, private cdRef: ChangeDetectorRef,private alertService:AlertService,
    public toastCtrl: ToastController) {
  
  }
  close(){
    this.modalController.dismiss()
  }
  ngOnInit() {
    this.authService.getToken();
  }
  handleNumber($event){
    console.log('number received',$event)
    let number=$event
    this.submitPinForm(number)
  }
  submitPinForm(pin){
    let navigationExtras: NavigationExtras
   
     navigationExtras = {
      state: {
        ...this.data,
        pin_recent: true
      }
    };
  
      let data = { "code": this.pin, "code_confirmation": pin  };
      this.presentLoading();
      this.authService.sendTransactionPin(data).subscribe(response => {
        if(response['status'] == 200){
          this.dismissLoading();
          this.modalController.dismiss('sucess')
          this.alertService.setExistError(false);
          this.alertService.setErrMessage('');
        }else if(response['status'] == 500){
          this.dismissLoading();
          this.alertService.setExistError(true);
          this.alertService.setErrMessage(response['errorMessage']);
        }
      },error => {
        this.dismissLoading();
      });
    
  }

  openHelp(){
    if(this.platform.is("ios")){
      this.options = {
        location: 'no',
        toolbarcolor: '#2196F3',
        toolbarposition: 'top',
        closebuttoncolor: '#ffffff',
        closebuttoncaption: 'Cerrar',
        lefttoright: "yes",
        hidenavigationbuttons: 'yes'
      };
    }else{
      this.options = {
        hideurlbar: 'yes',
        location: 'yes',
        toolbarcolor: '#2196F3',
        toolbarposition: 'top',
        closebuttoncolor: '#ffffff',
        closebuttoncaption: 'Cerrar',
        presentationstyle: 'pagesheet',
        hidenavigationbuttons: 'yes'
      };
    }
    const browser = this.iab.create(environment.HELP_URL,'_blank', this.options);
  }

  // Creación del loading
  async presentLoading() {
    this.isLoading = true;
    return await this.loadingCtrl.create({
    }).then(a => {
    a.present().then(() => {
        if (!this.isLoading) {
        a.dismiss().then(() => console.log());
        }
    });
    });
  }

  // Cierre del loading
  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
  }
  async presentToastError(message: any) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 5000,
      position: "top",
      color: "danger",
    });
    toast.present();
  }

}
