import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { ValidationUtils, StatusHttp } from 'src/app/static';
import { IUserCredential, IUserCredentialVerification } from 'src/app/models';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { ModUtils } from "src/app/static";
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { ToastController } from "@ionic/angular";
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-verify-pin',
  templateUrl: './verify-pin.component.html',
  styleUrls: ['./verify-pin.component.scss'],
})
export class VerifyPinComponent implements OnInit {
  @ViewChild('otp1', { static: false }) otp1;
  @ViewChild('otp2', { static: false }) otp2;
  @ViewChild('otp3', { static: false }) otp3;
  @ViewChild('otp4', { static: false }) otp4;
  dis1:boolean=false
  dis2:boolean=false
  dis3:boolean=false
  dis4:boolean=false
  //user: string;
  digit_1: number;
  digit_2: number;
  digit_3: number;
  digit_4: number;
  title: string;
  next_route: string;
  //type: string;
  text_type: string;
//  api_route: string;
  sentCode = false;
  button_class: string;
  existError = false;
  errorMsg: string;
  isActivateResend:boolean=true;
  sendWhatsappMessage:boolean=true;
  isLoading: boolean = false;
  options: InAppBrowserOptions;
  cont:any=0
   @Input() user:string=''
  @Input() type:string=''
   @Input() api_route:string=''
 
  constructor(
    public authService: AuthService,
    private modalController:ModalController,
    private iab: InAppBrowser,
    private platform: Platform,
    private cdRef: ChangeDetectorRef,
    public loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private alertService:AlertService
  ) {
  }

   checkValid(num) :boolean {
    const pattern = /^(0|[1-9][0-9]*)$/;
    if (!pattern.test(num)) {

      return false
    }
    return true
  }
  onBlur(e){
  
    var inputs = document.querySelectorAll('input');
    for (var i = 0; i < inputs.length; i++)
    {
        inputs[i].addEventListener('focusin', resetCursor, false);
        inputs[i].addEventListener('click', resetCursor, false);
    }
    function resetCursor(event)
    {
        setTimeout(()=> { event.target.selectionStart = event.target.value.length; }, 10);
    }
  
    this.cont=0
    if(e==1){
      this.dis1=true
      
      if(this.digit_3){
        setTimeout(()=>{
          this.otp4.setFocus()
          this.dis1=false
        },300)
      
      }
     else if(this.digit_2){
        setTimeout(()=>{
          this.otp3.setFocus()
          this.dis1=false
        },300)
      
      }
     else if(this.digit_1){
        setTimeout(()=>{
          this.otp2.setFocus()
          this.dis1=false
        },300)
      
      }
      else{
        this.dis1=false
      }
    }
    else if(e==2){
      this.dis2=true
      if(this.digit_3){
        setTimeout(()=>{
          this.otp4.setFocus()
          this.dis2=false
        },300)
      
      }
     else if(this.digit_2){
        setTimeout(()=>{
          this.otp3.setFocus()
          this.dis2=false
        },300)
        
      }
    else if(!this.digit_1){
          setTimeout(()=>{
            this.otp1.setFocus()
            this.dis2=false
          },300)
      }else{
        this.dis2=false
      }
    }
    else if(e==3){
      this.dis3=true
      if(this.digit_3){
        setTimeout(()=>{
          this.otp4.setFocus()
          this.dis3=false
        },300)
        
      }
      else if(!this.digit_1){
          setTimeout(()=>{
            this.otp1.setFocus()
            this.dis3=false
          },200)
        
      }
     else if(!this.digit_2){
        setTimeout(()=>{
          this.otp2.setFocus()
          this.dis3=false
        },200)
      } else{
        this.dis3=false
      }
    }
    else if(e==4){
      this.dis4=true
      if(!this.digit_1){
        setTimeout(()=>{
          this.otp1.setFocus()
          this.dis4=false
        },200)
      
      }
     else if(!this.digit_2){
        setTimeout(()=>{
          this.otp2.setFocus()
          this.dis4=false
        },200)
        
      }
     else if(!this.digit_3){
        setTimeout(()=>{
          this.otp3.setFocus()
          this.dis4=false
        },200)
        
      }else{
        this.dis4=false
      }
    }
    this.cdRef.detectChanges();
   
  }
  ionViewWillEnter(){
    setTimeout(() => {
      this.isActivateResend = false;
      this.sendWhatsappMessage=false
    }, 45000)
  }
  close(){
    this.modalController.dismiss()
  }
  ngOnInit() {
    if(this.type == 'email'){
      this.text_type = 'correo';
      this.title = 'Revisa tu correo';
    }else if(this.type == 'mobile'){
      this.text_type = 'teléfono';
      this.title = 'Revisa tu teléfono';
    }
      this.button_class = 'gordita-font verification_user';
    
  }
  saveData(type,model){
    this.presentLoading()
    let data_send={
       [type]:model,
       date_verification_mobile:true
     }
     this.authService.updateHighEmployee(data_send).subscribe(response => {
       if(response['status'] == 200){
         this.dismissLoading();
         //this.alertService.presentToast(response['successMessage'])
          this.alertService.presentToast('Verificación Exitosa')
         this.modalController.dismiss('sucess')
       }else if(response['status'] == 500){
         this.dismissLoading();
         this.alertService.presentToastError(response['errorMessage'])
       }
     },(err)=>{
       this.dismissLoading();
       this.alertService.presentToastError('No se puede actuliazar los datos')
       console.log(err)
     })
  }
  setVerification(){
    let data = ValidationUtils.validateEmail(this.user) ? { 'email': this.user, 'token': `${this.digit_1}${this.digit_2}${this.digit_3}${this.digit_4}`, 'route': this.api_route } : { 'mobile' : this.user, 'token': `${this.digit_1}${this.digit_2}${this.digit_3}${this.digit_4}`, 'route': this.api_route };
    this.presentLoading();
    this.authService.setVerificationUser(data).subscribe(response => {
      if(response['status'] == StatusHttp.Success){
        this.dismissLoading();
       this.saveData(this.type,this.user)
      }else if(response['status'] == StatusHttp.ServerError){
        this.dismissLoading();
        this.sentCode = true;
        this.existError = true;
        this.errorMsg = response['errorMessage'];
        this.presentToastError(this.errorMsg);
      }
    },error => {
      this.dismissLoading();
    })
}


  resendVerification(){

    if(!this.isActivateResend){
      this.sendVerificationMobile();

    }

    this.isActivateResend = true;
    this.sendWhatsappMessage=true
    setTimeout(() => {
      this.isActivateResend = false;
      this.sendWhatsappMessage=false
    }, 45000);

  }

  sendVerificationMobile() {
    this.authService.sendVerificationMobile(this.getUserCredentials()).subscribe(response => {
      if(response['status'] == StatusHttp.ServerError && typeof response['data'] == 'undefined'){
        console.log(response);
      }
    })
  }

  private getUserCredentials() {
    return ValidationUtils.validateEmail(this.user) ? { 'email': this.user } : { 'mobile' : this.user };
  }

  limitPin(index,event){
    this.cdRef.detectChanges();

    switch(index){
      case 1:
        this.digit_1 = ModUtils.constrainNumToDigitLength(event.target.value, 1);
        break;
      case 2:
        this.digit_2 = ModUtils.constrainNumToDigitLength(event.target.value, 1);
        break;
      case 3:
        this.digit_3 = ModUtils.constrainNumToDigitLength(event.target.value, 1);
        break;
      case 4:
        this.digit_4 = ModUtils.constrainNumToDigitLength(event.target.value, 1);
        break;
    }
  }
  tpController(event,next,prev,id){
    this.dis1=false
    this.dis2=false
    this.dis3=false
    this.dis4=false
    if(event.target.value.length < 1 && prev){
        this.cont=1
        prev.setFocus();    
    }
    else if(next && event.target.value.length>0){
      if(this.cont==1){
      
      
      if(event.keyCode==49){
        if(id==1)
          this.digit_2=1
        
        if(id==2)
          this.digit_3=1
        
        if(id==3)
          this.digit_4=1
        
        next.setFocus();
      }
      if(event.keyCode==50){
      
        if(id==1)
          this.digit_2=2
        
        if(id==2)
          this.digit_3=2
        
        if(id==3)
          this.digit_4=2
        
        next.setFocus();
      }
      if(event.keyCode==51){
      
        if(id==1)
          this.digit_2=3
        
        if(id==2)
          this.digit_3=3
        
        if(id==3)
          this.digit_4=3
        
        next.setFocus();
      }
     if(event.keyCode==52){
      
        if(id==1)
          this.digit_2=4
        
        if(id==2)
          this.digit_3=4
        
        if(id==3)
          this.digit_4=4
        
        next.setFocus();
      }
     if(event.keyCode==53){
      
        if(id==1)
          this.digit_2=5
        
        if(id==2)
          this.digit_3=5
        
        if(id==3)
          this.digit_4=5
        
        next.setFocus();
      }
      if(event.keyCode==54){
      
        if(id==1)
          this.digit_2=6
        
       if(id==2)
          this.digit_3=6
        
       if(id==3)
          this.digit_4=6
        
        next.setFocus();
      }
     if(event.keyCode==55){
      
        if(id==1)
          this.digit_2=7
        
        if(id==2)
          this.digit_3=7
        
        if(id==3)
          this.digit_4=7
        
        next.setFocus();
      }
     if(event.keyCode==56){
      
        if(id==1)
          this.digit_2=8
        
        if(id==2)
          this.digit_3=8
        
        if(id==3)
          this.digit_4=8
        
        next.setFocus();
      }
      if(event.keyCode==57){
      
        if(id==1)
          this.digit_2=9
        
        if(id==2)
          this.digit_3=9
        
        if(id==3)
          this.digit_4=9
        
         next.setFocus();
      }
      if(event.keyCode==48){
      
        if(id==1)
          this.digit_2=0
        
        if(id==2)
          this.digit_3=0
        
       if(id==3)
          this.digit_4=0
        
        next.setFocus();
      }
     
    }else{
    
      if(this.checkValid(event.target.value)){
      this.cont=0
      next.setFocus();
     }else{
    
      event.target.value=null
      this.cdRef.detectChanges();
      }
    } 
    }
    else if(event.keyCode == 13){
     this.setVerification()
    }
    else {
      
     if(!this.checkValid(event.target.value))
      {
        event.target.value=null
        this.cdRef.detectChanges();
        }  
    this.cont=0
   // return 0;
    } 
  }
  tpControllerOld(event,next,prev){

    if(event.target.value.length < 1 && prev){
      prev.setFocus()
    }
    else if(next && event.target.value.length>0){
      next.setFocus();
    }
    else if(event.keyCode == 13){
      this.setVerification();
    }
    else {
      return 0;
    } 
  }

  openHelp(){
    if(this.platform.is("ios")){
      this.options = {
        location: 'no',
        toolbarcolor: '#2196F3',
        toolbarposition: 'top',
        closebuttoncolor: '#ffffff',
        closebuttoncaption: 'Cerrar',
        lefttoright: "yes",
        hidenavigationbuttons: 'yes'
      };
    }else{
      this.options = {
        hideurlbar: 'yes',
        location: 'yes',
        toolbarcolor: '#2196F3',
        toolbarposition: 'top',
        closebuttoncolor: '#ffffff',
        closebuttoncaption: 'Cerrar',
        presentationstyle: 'pagesheet',
        hidenavigationbuttons: 'yes'
      };
    }
    const browser = this.iab.create(environment.HELP_URL,'_blank', this.options);
  }

  // Creación del loading
  async presentLoading() {
    this.isLoading = true;
    return await this.loadingCtrl.create({
    }).then(a => {
    a.present().then(() => {
        if (!this.isLoading) {
        a.dismiss().then(() => console.log());
        }
    });
    });
  }

  // Cierre del loading
  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
  }

  async presentToastError(message: any) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 2000,
      position: 'top',
      color: 'danger'
    });
    toast.present();
  }
  sendWhatsapp(){

    this.sendVerificationMobileWhatsapp();
    this.sendWhatsappMessage=true
    this.isActivateResend = true;
    setTimeout(() => {
      this.sendWhatsappMessage=false
      this.isActivateResend = false;
    }, 45000);

  }
  sendVerificationMobileWhatsapp() {
    this.authService.sendVerificationMobile({...this.getUserCredentials(),whatsapp:true}).subscribe(response => {
      if(response['status'] == StatusHttp.ServerError && typeof response['data'] == 'undefined'){
        console.log(response);
      }
    })
  }
}
