import { Component, OnInit, ChangeDetectorRef, ViewChild, Input, Output,EventEmitter } from '@angular/core';
import { AppStorageKey, ModUtils, StatusHttp } from "src/app/static";
import { LoadingController, Platform } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { AuthTokenObject } from 'src/app/models';
import { AuthService } from 'src/app/services/auth.service';
import { Storage } from '@ionic/storage';


@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})

export class OtpComponent implements OnInit {
  @ViewChild('otp1', { static: false }) otp1;
  @ViewChild('otp2', { static: false }) otp2;
  @ViewChild('otp3', { static: false }) otp3;
  @ViewChild('otp4', { static: false }) otp4;
  
  digit_11: number;
  digit_21: number;
  digit_31: number;
  digit_41: number;
 dis1:boolean=false
 dis2:boolean=false
 dis3:boolean=false
 dis4:boolean=false

  isLoading: boolean = false;
  cont:any=0
  @Input() subtitle?: string='';
  @Input() step?: string='';
  errorMsg: string=''
  existError:boolean = true;

  @Output() number = new EventEmitter<any>();
  sendWhatsappMessage:boolean=true
  TokenStored: AuthTokenObject;
  constructor( 
    private cdRef: ChangeDetectorRef,
    public loadingCtrl: LoadingController,
    private platform:Platform,
    private alertService:AlertService,
   private authService:AuthService,
   private storage: Storage) {
     this.getTypeLogin()
      this.alertService.errorMsg.subscribe((res) => {
        this.errorMsg = res;
      });
      this.alertService.errorExist.subscribe((res) => {
        this.existError = res;
        if(!this.existError){
            this.digit_11=null
            this.digit_21=null
            this.digit_31=null
            this.digit_41=null
            this.cont=0
            this.errorMsg=''
        }
      });
     }
 async getTypeLogin(){
  this.TokenStored = await this.storage.get(AppStorageKey.AuthTokenObject);
  setTimeout(() => {
    if(this.TokenStored.login_type == 'mobile')
    this.sendWhatsappMessage=false
  }, 45000)
 }
  ngOnInit() {
    
  }
  ngOnDestroy(){
    this.alertService.setExistError(false);
    this.alertService.setErrMessage('');
  }
  tpController(event,next,prev,id){
    this.dis1=false
    this.dis2=false
    this.dis3=false
    this.dis4=false
   
    if(event.target.value.length < 1 && prev){
      
        this.cont=1
        prev.setFocus();    
    }
    else if(next && event.target.value.length>0){
      

      if(this.cont==1){
      
      if(event.keyCode==49){
        if(id==1)
          this.digit_21=1
        
        if(id==2)
          this.digit_31=1
        
        if(id==3)
          this.digit_41=1
        
        next.setFocus();
      }
      if(event.keyCode==50){
      
        if(id==1)
          this.digit_21=2
        
        if(id==2)
          this.digit_31=2
        
        if(id==3)
          this.digit_41=2
        
        next.setFocus();
      }
      if(event.keyCode==51){
      
        if(id==1)
          this.digit_21=3
        
        if(id==2)
          this.digit_31=3
        
        if(id==3)
          this.digit_41=3
        
        next.setFocus();
      }
     if(event.keyCode==52){
      
        if(id==1)
          this.digit_21=4
        
        if(id==2)
          this.digit_31=4
        
        if(id==3)
          this.digit_41=4
        
        next.setFocus();
      }
     if(event.keyCode==53){
      
        if(id==1)
          this.digit_21=5
        
        if(id==2)
          this.digit_31=5
        
        if(id==3)
          this.digit_41=5
        
        next.setFocus();
      }
      if(event.keyCode==54){
      
        if(id==1)
          this.digit_21=6
        
       if(id==2)
          this.digit_31=6
        
       if(id==3)
          this.digit_41=6
        
        next.setFocus();
      }
     if(event.keyCode==55){
      
        if(id==1)
          this.digit_21=7
        
        if(id==2)
          this.digit_31=7
        
        if(id==3)
          this.digit_41=7
        
        next.setFocus();
      }
     if(event.keyCode==56){
      
        if(id==1)
          this.digit_21=8
        
        if(id==2)
          this.digit_31=8
        
        if(id==3)
          this.digit_41=8
        
        next.setFocus();
      }
      if(event.keyCode==57){
      
        if(id==1)
          this.digit_21=9
        
        if(id==2)
          this.digit_31=9
        
        if(id==3)
          this.digit_41=9
        
         next.setFocus();
      }
      if(event.keyCode==48){
      
        if(id==1)
          this.digit_21=0
        
        if(id==2)
          this.digit_31=0
        
       if(id==3)
          this.digit_41=0
        
        next.setFocus();
      }
     
    }else{
  
      if(this.checkValid(event.target.value)){
        
      this.cont=0
      next.setFocus();
     }else{
     
      event.target.value=null
      this.cdRef.detectChanges();
      }
    } 
    }
    else if(event.keyCode == 13){
     this.sendPin()
    }
    else {
     if(!this.checkValid(event.target.value))
      {
        event.target.value=null
        this.cdRef.detectChanges();
        }  
    this.cont=0
   // return 0;
    } 
  }
  sendPin(){
    if(this.digit_11 != null && this.digit_11 && this.digit_21 && this.digit_31 && this.digit_41 && this.digit_21 != null && this.digit_31 != null && this.digit_41 != null){
      let data=this.digit_11.toString() + this.digit_21.toString()+
      this.digit_31.toString()+this.digit_41.toString()
        this.number.emit(data)
      
    }else{
      this.existError = true;
      let tmp='Debe llenar todos los dígitos';
      this.alertService.setExistError(true);
      this.alertService.setErrMessage(tmp);
    }
   
  }
  checkValid(num) :boolean {
    const pattern = /^(0|[1-9][0-9]*)$/;
    if (!pattern.test(num)) {

      return false
    }
    return true
  }
  onBlur(e){
  
    var inputs = document.querySelectorAll('input');
    for (var i = 0; i < inputs.length; i++)
    {
        inputs[i].addEventListener('focusin', resetCursor, false);
        inputs[i].addEventListener('click', resetCursor, false);
    }
    function resetCursor(event)
    {
        setTimeout(()=> { event.target.selectionStart = event.target.value.length; }, 10);
    }
  
    this.cont=0
    if(e==1){
      this.dis1=true
      
      if(this.digit_31){
        setTimeout(()=>{
          this.otp4.setFocus()
          this.dis1=false
        },300)
      
      }
     else if(this.digit_21){
        setTimeout(()=>{
          this.otp3.setFocus()
          this.dis1=false
        },300)
      
      }
     else if(this.digit_11){
        setTimeout(()=>{
          this.otp2.setFocus()
          this.dis1=false
        },300)
      
      }
      else{
        this.dis1=false
      }
    }
    else if(e==2){
      this.dis2=true
      if(this.digit_31){
        setTimeout(()=>{
          this.otp4.setFocus()
          this.dis2=false
        },300)
      
      }
     else if(this.digit_21){
        setTimeout(()=>{
          this.otp3.setFocus()
          this.dis2=false
        },300)
        
      }
    else if(!this.digit_11){
          setTimeout(()=>{
            this.otp1.setFocus()
            this.dis2=false
          },300)
      }else{
        this.dis2=false
      }
    }
    else if(e==3){
      this.dis3=true
      if(this.digit_31){
        setTimeout(()=>{
          this.otp4.setFocus()
          this.dis3=false
        },300)
        
      }
      else if(!this.digit_11){
          setTimeout(()=>{
            this.otp1.setFocus()
            this.dis3=false
          },200)
        
      }
     else if(!this.digit_21){
        setTimeout(()=>{
          this.otp2.setFocus()
          this.dis3=false
        },200)
      } else{
        this.dis3=false
      }
    }
    else if(e==4){
      this.dis4=true
      if(!this.digit_11){
        setTimeout(()=>{
          this.otp1.setFocus()
          this.dis4=false
        },200)
      
      }
     else if(!this.digit_21){
        setTimeout(()=>{
          this.otp2.setFocus()
          this.dis4=false
        },200)
        
      }
     else if(!this.digit_31){
        setTimeout(()=>{
          this.otp3.setFocus()
          this.dis4=false
        },200)
        
      }else{
        this.dis4=false
      }
    }
    this.cdRef.detectChanges();
   
  }
  limitPin(index,event){
    this.cdRef.detectChanges();
  
     switch(index){
       case 1:
        
           this.digit_11 = ModUtils.constrainNumToDigitLength(event.target.value, 1);
        
         break;
       case 2:
      
           this.digit_21 = ModUtils.constrainNumToDigitLength(event.target.value, 1);
        
         break;
       case 3:
       
           this.digit_31 = ModUtils.constrainNumToDigitLength(event.target.value, 1);
        
         break;
       case 4:
        
           this.digit_41 = ModUtils.constrainNumToDigitLength(event.target.value, 1);
        
         break;
        

     }
   }
   async sendWhatsapp(){
    this.sendVerificationMobileWhatsapp();
    this.sendWhatsappMessage=true
    setTimeout(() => {
      this.sendWhatsappMessage=false
    }, 45000);
    
  }
  async sendVerificationMobileWhatsapp() {
    (await this.authService.sendPinRecoverCode('SI')).subscribe(response => {
      if(response['status'] == StatusHttp.ServerError && typeof response['data'] == 'undefined'){
        console.log(response);
      }    
    })
  }
}
