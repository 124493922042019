import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RecognizeByPageRoutingModule } from './recognize-by-routing.module';

import { RecognizeByPage } from './recognize-by.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RecognizeByPageRoutingModule
  ],
  declarations: [RecognizeByPage]
})
export class RecognizeByPageModule {}
