import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-survey-not-available',
  templateUrl: './survey-not-available.component.html',
  styleUrls: ['./survey-not-available.component.scss'],
})
export class SurveyNotAvailableComponent implements OnInit {

  constructor(public modalController : ModalController, public router: Router) { }

  ngOnInit() {}

  redirectDashboard(){
    this.router.navigate(['/dashboard']);
    this.modalController.dismiss();
  }

}
