import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  @ViewChild('videoPlayer', {static: false}) videoPlayer: any;
  @Input() path_video:string=''
  @Input() title_video?:string=''
  constructor(private modalController:ModalController) { }

  ngOnInit() {}
  closeVideo(){
    this.modalController.dismiss();
   }
   ngOnDestroy() {
    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.stop;
      //this.videoPlayer.nativeElement.removeAttribute('src');
    }
  }
}
