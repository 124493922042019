import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopoverController} from '@ionic/angular';
import { ModUtils, ValidationUtils } from '../../../static/utils';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-input-custom',
  templateUrl: './input-custom.component.html',
  styleUrls: ['./input-custom.component.scss'],
})
export class InputCustomComponent implements OnInit {
  model:any=''
  constructor(
    private alertService:AlertService,private popoverController: PopoverController, private cdRef: ChangeDetectorRef,
  ) {
  
}
  ngOnInit() { }
  async accept(){
    if(!this.model){
      this.alertService.presentToastError('El campo no puede estar vacío')
       return
     }
  
   if(!ValidationUtils.validatePhoneNumber(this.model)){
         this.alertService.presentToastError('Ingrese un número válido')
         return
   }
    
    await this.popoverController.dismiss(this.model);
  }
  constrainPhoneNumber(value: number) {
    this.cdRef.detectChanges();
    this.model = ModUtils.constrainNumToDigitLength(value, 10);
  }

}
