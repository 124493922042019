import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { StatusHttp } from 'src/app/static';
import { ModalController, ToastController, LoadingController, Platform } from '@ionic/angular';
import { SignaturePad } from 'angular2-signaturepad';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit {

  @ViewChild('signaturePad', {static: false}) signaturePad: SignaturePad;

   signaturePadOptions = { 
    maxWidth:1,
    minWidth: 1,
    canvasWidth: window.innerWidth + 170,
    canvasHeight: 120
  };

  signature: string = '';

  isLoading: boolean = false;

  user: any;

  constructor(
    public modalController : ModalController, 
    private router: Router, 
    private authService:AuthService,
    public loadingCtrl: LoadingController,
    private toastController:ToastController,
    private screenOrientation: ScreenOrientation,
    private platform:Platform
  ) { 
    if (this.platform.is('cordova'))
    this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE) }

  ngOnInit() {
    this.authService.user().subscribe(
      (data:any)=>{
        this.user = data;
      }
    );
  }
  ionViewWillLeave(){
    if (this.platform.is('cordova')) 
    this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT)
  }
  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    //console.log(this.signaturePad.toDataURL());
    this.signature = this.signaturePad.toDataURL();
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }

  clear(){
    this.signaturePad.clear();
    this.signature = '';
  }

  closeModal(){
    this.modalController.dismiss();
  }

  saveSignature(){
    if(this.signature != ''){
      let data = { 
        signature: this.signature
      };
      this.presentLoading();
      this.authService.saveSignature(data).subscribe(response => {
        if (response['status'] == StatusHttp.Success) {
          this.dismissLoading();
          this.modalController.dismiss({'action': 'signature_saved', 'signature': response['data']['signature']});
        }else if(response['status'] == StatusHttp.ServerError){
          this.dismissLoading();
          this.presentToast(response['errorMessage'],'danger');
        }
      });
    }else{
      this.presentToast('Debe llenar el campo firma','danger');
    }
  }

  // Presenta el toast con el error
  async presentToast(msg,color) {
    const toast = await this.toastController.create({
        message: msg,
        duration: 5000,
        position: 'top',
        color: color,
        cssClass: 'toast',
    });
    toast.present();
  }

  // Creación del loading
  async presentLoading() {
    this.isLoading = true;
    return await this.loadingCtrl.create({
    }).then((a) => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss();
        }
      });
    });
  }

  // Cierre del loading
  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss();
  }

}
