import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
})
export class TermsModalComponent implements OnInit {

  @Input() signature_url: string;

  user: any;

  constructor(
    public modalController : ModalController,
    private authService:AuthService
  ) { }

  ngOnInit() {
    this.authService.getToken().then(() => {
      if (this.authService.isLoggedIn) { 
        this.authService.user().subscribe((data:any)=>{
          console.log("data", data);
          this.user = data;
        });
      }
    });
  }

  closeModal(){
    this.modalController.dismiss();
  }
}
