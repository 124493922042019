import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit {

  @Input() placeholder: string;
  @Input() cancel: string;
  @Input() ok: string;
  @Input() format: string;
  @Input() formatPicker: string;
  @Input() min: string;
  @Input() max: string;
  @Input() control: AbstractControl;

  @Output() changeEmitter = new EventEmitter<string>();

  constructor() {
    this.format = 'YYYY-MM-DD';
    this.formatPicker = 'YYYY-MMMM-DD';
    this.cancel = 'Cancelar';
    this.ok = 'Confirmar';
    this.control = new FormControl();
  }

  ngOnInit() {
  }

  onChangeDate(event: any) {
    this.changeEmitter.emit(event.detail.value);
  }

  clearSinceDate() {
    this.control.setValue('');
  }

}
