import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { LoadingController, ToastController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HTTP } from '@ionic-native/http/ngx';
import { AuthService } from './auth.service';
import { Router, NavigationExtras } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {

  items:any;

  isLoading: boolean = false;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    })
  }

  constructor(    
    private http: HttpClient,
    public loadingCtrl: LoadingController,
    private toastController:ToastController,
    private nativeHTTP: HTTP,
    private authService:AuthService,
    public router: Router
  ) { }

  // Presenta el toast con el error
  async presentToast(msg,color) {
    const toast = await this.toastController.create({
        message: msg,
        duration: 5000,
        position: 'top',
        color: color,
        cssClass: 'toast',
    });
    toast.present();
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.'
    );
  };

  async createMembership(formData,nextUrl,data) {

    this.nativeHTTP.setDataSerializer('multipart');

    /*let file = formData.get('document');

    console.log('File: '+JSON.stringify(file));

    console.log('entro a enviar comentarios');*/

    const hasPreviousSession = await this.authService.updateAuthStateIfTokenProvided();

    let token_header = `${this.authService.token.token_type} ${this.authService.token.token}`;

    console.log('Token: '+token_header);

    let headers_request = { Authorization: token_header };

    let navigationExtras: NavigationExtras = {
      state: {
        data: data,
      }
    };


    this.presentLoading();
    await this.nativeHTTP.sendRequest(environment.API_URL + '/insurances/create',{
      method: 'post',
      data: formData,
      headers: headers_request,
    })
    .then(response => {
      // prints 200
      console.log("Response successfull status: "+response.status);
      console.log("Response successfull body: "+JSON.stringify(response));
      let data = JSON.parse(response.data);
      if(data['status'] == 200){
        this.dismissLoading();
        this.router.navigate([nextUrl],navigationExtras);
      }else if(data['status'] == 500){
        this.dismissLoading();
        this.presentToast(data['errorMessage'],'danger');
      }
    })
    .catch(error => {
      // prints 403
      console.log("Response status: "+error.status);
      // prints Permission denied
      console.log("Response error: "+error.error);
      console.log('error en carga', JSON.stringify(error));
      this.dismissLoading();
      this.presentToast('File native upload error: '+JSON.stringify(error),'danger');
    });
  }

  loadBeneficiaries(type){
    this.items = [];
    this.getBeneficiaries(type).subscribe(response => {
      if(response['status'] == 200){
        let data = response['data'];
        data['beneficiaries'].forEach(element => {
          this.items.push(element);
        }); 
      }
    });
  }

  createBeneficiary(data?:any) {
    return this.http.post(environment.API_URL + '/insurances/create_beneficiary',data, this.httpOptions)
    .pipe(
      tap(response => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  hasAllThePercentage(data?:any) {
    return this.http.post(environment.API_URL + '/insurances/has_all_percentage',data, this.httpOptions)
    .pipe(
      tap(response => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  updateBeneficiary(data?:any) {
    return this.http.post(environment.API_URL + '/insurances/update_beneficiary',data, this.httpOptions)
    .pipe(
      tap(response => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  saveSignature(data?:any) {
    return this.http.post(environment.API_URL + '/insurances/save_signature',data, this.httpOptions)
    .pipe(
      tap(response => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getMembership() {
    return this.http
      .get(environment.API_URL+'/insurances/get_states')
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }

  getBeneficiaries(type) {
    return this.http
      .get(environment.API_URL+'/insurances/getBeneficiaries/'+type)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }

 Policiesdelet(insurence:any){
  return this.http
      .get(environment.API_URL+'/insurances/update/policy-status/'+insurence)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }
  // Creación del loading
  async presentLoading() {
    this.isLoading = true;
    return await this.loadingCtrl.create({})
    .then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => console.log());
        }
      });
    });
  }

  // Cierre del loading
  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
  }

  membreship(form: any){
    return this.http.post(environment.API_URL + "/insurances/create", form, this.httpOptions)
    .pipe(
      tap(response => {
        return response;
      }),
      catchError(this.handleError)
    );
  };
  membershipFamily(form: any){
    return this.http.post(environment.API_URL + "/insurances/create_family", form, this.httpOptions)
    .pipe(
      tap(response => {
        return response;
      }),
      catchError(this.handleError)
    );
  };


    
}
