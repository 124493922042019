import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Storage } from '@ionic/storage';
import { AppStorageKey } from '../static';
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: "root",
})
export class AuthSessionGuard implements CanActivate {
  constructor(private router: Router, private navCtrl: NavController, private storage: Storage, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise(async (resolve) => {
      const hasPreviousSession = await this.authService.updateAuthStateIfTokenProvided();
      const IsOpenFromNotification = await this.storage.get(AppStorageKey.IsOpenFromNotification);
      let canRedirect = IsOpenFromNotification == null ? true : false;
      console.log('is Open from notification',IsOpenFromNotification);
      if (hasPreviousSession && canRedirect) { this.navCtrl.navigateRoot(["restoring-session"]); }
      if (!canRedirect){ this.storage.remove(AppStorageKey.IsOpenFromNotification);}
      resolve(!hasPreviousSession);
    });
  }
}
