import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ImportsPageRoutingModule } from './imports-routing.module';

import { ImportsPage } from './imports.page';
import { ReceiveRecognizePage } from 'src/app/modules/recognize-team/receive-recognize/receive-recognize.page';
import { RecognizeByPageModule } from 'src/app/modules/recognize-team/recognize-by/recognize-by.module';
import { ViewEmployeeVoicePageModule } from 'src/app/modules/employee-voice/view-employee-voice/view-employee-voice.module';


@NgModule({
  entryComponents: [
    ReceiveRecognizePage
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ImportsPageRoutingModule,
    ViewEmployeeVoicePageModule,
    RecognizeByPageModule
  ],
  declarations: [
    ImportsPage,
    ReceiveRecognizePage
  ]
})
export class ImportsPageModule {}
