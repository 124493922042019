import { Component, OnInit } from '@angular/core';
import { PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-menu-docs',
  templateUrl: './menu-docs.component.html',
  styleUrls: ['./menu-docs.component.scss'],
})
export class MenuDocsComponent implements OnInit {
 
  constructor(
    private popoverController: PopoverController,
  ) {
  
}
  ngOnInit() { }
  async setOption(key){
    await this.popoverController.dismiss(key);
  }

}
